<template>
  <div class="has-nav-bar view-user-todo-list">
    <van-nav-bar title="我的待办" fixed left-arrow @click-left="onClickLeft" />
    <div class="user-todo-list-wrap">
      <van-icon
        class-prefix="iconfont"
        name="guolv"
        class="filter-btn"
        @click="filterHandle"
      >
      </van-icon>
      <van-tabs v-model="activeName" @click="onTabsClick">
        <van-tab
          title="待办事项"
          name="todo"
          :badge="todoData.total ? todoData.total : ''"
        >
          <div class="user-todo-list-container">
            <van-pull-refresh v-model="todoRefreshing" @refresh="onTodoRefresh">
              <van-list
                v-model="todoLoading"
                :finished="todoFinished"
                finished-text="没有更多了"
                :offset="offset"
                class="block-space-left-right"
                :error.sync="todoError"
                error-text="加载失败，请稍后再试！"
                @load="onTodoLoad"
              >
                <van-swipe-cell v-for="item in todoData.list" :key="item.id">
                  <user-todo-list-item
                    :key="item.id"
                    :data="item"
                    :active-name="activeName"
                  />
                </van-swipe-cell>
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
        <van-tab title="已办事项" name="done">
          <div class="user-todo-list-container">
            <van-pull-refresh v-model="doneRefreshing" @refresh="onDoneRefresh">
              <van-list
                v-model="doneLoading"
                :finished="doneFinished"
                finished-text="没有更多了"
                class="block-space-left-right"
                :error.sync="doneError"
                error-text="加载失败，请稍后再试！"
                @load="onDoneLoad"
              >
                <van-swipe-cell v-for="item in doneData.list" :key="item.id">
                  <user-todo-list-item
                    :key="item.id"
                    :data="item"
                    :active-name="activeName"
                  />
                </van-swipe-cell>
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
        <van-tab title="我创建的" name="mine">
          <div class="user-todo-list-container">
            <van-pull-refresh v-model="mineRefreshing" @refresh="onMineRefresh">
              <van-list
                v-model="mineLoading"
                :finished="mineFinished"
                finished-text="没有更多了"
                class="block-space-left-right"
                :error.sync="mineError"
                error-text="加载失败，请稍后再试！"
                @load="onMineLoad"
              >
                <van-swipe-cell v-for="item in mineData.list" :key="item.id">
                  <user-todo-list-item :key="item.id" :data="item" />
                </van-swipe-cell>
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup
      v-model="filterPopupStatus"
      position="bottom"
      class="filter-popup"
    >
      <div class="popup-content">
        <div>重要程度</div>
        <van-radio-group v-model="filter.degree" direction="horizontal">
          <van-radio
            v-for="item in degreeList"
            :key="item.value"
            :name="item.value"
            style="width:26%"
          >
            {{ item.label }}
          </van-radio>
        </van-radio-group>
        <div>待办类型</div>
        <van-radio-group v-model="filter.type" direction="horizontal">
          <van-radio
            v-for="item in typeList"
            :key="item.value"
            :name="item.value"
            style="width:26%"
          >
            {{ item.label }}
          </van-radio>
        </van-radio-group>
        <div>创建时间</div>
        <div>
          <date-time v-model="filterDate" type="date"></date-time>
        </div>
        <div class="not-sticky">业务类型</div>
        <van-radio-group v-model="filter.moduleId" direction="horizontal">
          <van-radio
            v-for="item in moduleList"
            :key="item.value"
            :name="item.value"
          >
            {{ item.label }}
          </van-radio>
        </van-radio-group>
      </div>
      <div class="popup-footer">
        <van-button type="danger" class="reset-btn" @click="resetHandle">
          重置
        </van-button>
        <van-button type="info" class="handle-btn" @click="submitHandle">
          确定
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  NavBar,
  Cell,
  Tag,
  Tabs,
  Tab,
  List,
  SwipeCell,
  Button,
  Icon,
  PullRefresh,
  Popup,
  RadioGroup,
  Radio
} from "vant";
import messageApi from "../../api/message";
import { mapState } from "vuex";
import UserTodoListItem from "./UserTodoListItem";
export default {
  name: "UserTodoList",
  components: {
    UserTodoListItem,
    [NavBar.name]: NavBar,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [SwipeCell.name]: SwipeCell,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [List.name]: List
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      activeName: "todo",
      filterPopupStatus: false,
      filter: {},
      filterDate: {
        start: "",
        end: ""
      },
      degreeList: [],
      typeList: [],
      moduleList: [],
      size: 10,
      offset: 100,
      todoLoading: false,
      todoFinished: false,
      todoRefreshing: false,
      todoError: false,
      todoPage: 1,
      todoData: {
        total: 0,
        list: []
      },
      doneLoading: false,
      doneFinished: false,
      doneRefreshing: false,
      doneError: false,
      donePage: 1,
      doneData: {
        total: 0,
        list: []
      },
      mineLoading: false,
      mineFinished: false,
      minePage: 1,
      mineRefreshing: false,
      mineError: false,
      mineData: {
        total: 0,
        list: []
      }
    };
  },
  created() {
    this.initDegrees();
    this.initTypeList();
    this.initModuleList();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {},
    onTabsClick(name) {
      this.submitHandle();
    },
    filterHandle() {
      this.filterPopupStatus = true;
    },
    resetHandle() {
      this.filter = {};
      this.filterDate = {};
    },
    submitHandle() {
      if (this.activeName === "todo") {
        this.onTodoRefresh();
      } else if (this.activeName === "done") {
        this.onDoneRefresh();
      } else if (this.activeName === "mine") {
        this.onMineRefresh();
      }
      this.filterPopupStatus = false;
    },
    onTodoLoad() {
      this.getTodoData(this.filter);
    },
    onTodoRefresh() {
      this.todoPage = 1;
      this.todoFinished = false;
      this.todoLoading = true;
      this.onTodoLoad();
    },
    onDoneLoad() {
      this.getDoneData(this.filter);
    },
    onDoneRefresh() {
      this.donePage = 1;
      this.doneFinished = false;
      this.doneLoading = true;
      this.onDoneLoad();
    },
    onMineLoad() {
      this.getMineData(this.filter);
    },
    onMineRefresh() {
      this.minePage = 1;
      this.mineFinished = false;
      this.mineLoading = true;
      this.onMineLoad();
    },
    dateSwitch(data) {
      return (data || []).map(i => {
        if (i.moduleName === "PSI管理") {
          i.title = `${i.title}请登录电脑端处理。`;
        }
        return i;
      });
    },
    getTodoData(params = {}) {
      this.todoLoading = true;
      messageApi
        .getMessagePageTasks({
          taskStatus: 0,
          page: this.todoPage,
          size: this.size,
          ...params,
          startDate: this.filterDate.start,
          endDate: this.filterDate.end,
          sort: "createTime"
        })
        .then(res => {
          if (this.todoRefreshing) {
            this.todoData.list = [];
            this.todoRefreshing = false;
          }

          res.list = this.dateSwitch(res.list);
          this.todoData.total = res.total;
          this.todoData.list =
            this.todoPage === 1
              ? res.list
              : this.todoData.list.concat(res.list);
          this.todoLoading = false;
          if (res.total > this.size * this.todoPage) {
            this.todoPage++;
          } else {
            this.todoFinished = true;
          }
        })
        .catch(() => {
          this.todoLoading = false;
          this.todoRefreshing = false;
          this.todoError = true;
        });
    },
    getDoneData(params = {}) {
      this.doneLoading = true;
      messageApi
        .getMessagePageTasks({
          taskStatus: 1,
          page: this.donePage,
          size: this.size,
          ...params,
          startDate: this.filterDate.start,
          endDate: this.filterDate.end,
          sort: "createTime"
        })
        .then(res => {
          if (this.doneRefreshing) {
            this.doneData.list = [];
            this.doneRefreshing = false;
          }
          res.list = this.dateSwitch(res.list);
          this.doneData.total = res.total;
          this.doneData.list =
            this.donePage === 1
              ? res.list
              : this.doneData.list.concat(res.list);
          this.doneLoading = false;
          if (res.total > this.size * this.donePage) {
            this.donePage++;
          } else {
            this.doneFinished = true;
          }
        })
        .catch(() => {
          this.doneLoading = false;
          this.doneRefreshing = false;
          this.doneError = true;
        });
    },
    getMineData(params = {}) {
      this.mineLoading = true;
      messageApi
        .getMessagePageTasks({
          sender: this.userInfo.id,
          page: this.minePage,
          size: this.size,
          ...params,
          startDate: this.filterDate.start,
          endDate: this.filterDate.end,
          sort: "createTime"
        })
        .then(res => {
          if (this.mineRefreshing) {
            this.mineData.list = [];
            this.mineRefreshing = false;
          }
          res.list = this.dateSwitch(res.list);
          this.mineData.total = res.total;
          this.mineData.list =
            this.minePage === 1
              ? res.list
              : this.mineData.list.concat(res.list);
          this.mineLoading = false;
          if (res.total > this.size * this.minePage) {
            this.minePage++;
          } else {
            this.mineFinished = true;
          }
        })
        .catch(() => {
          this.mineLoading = false;
          this.mineRefreshing = false;
          this.mineError = true;
        });
    },
    initDegrees() {
      messageApi.getMessageDegrees().then(res => {
        this.degreeList = res;
      });
    },
    initTypeList() {
      messageApi.getMessageTaskTypes().then(res => {
        this.typeList = res;
      });
    },
    initModuleList() {
      const params = {
        type: 1
      };
      messageApi.getMessageModulesByType(params).then(res => {
        this.moduleList = res;
      });
    }
  }
};
</script>
<style lang="scss">
.view-user-todo-list {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  .user-todo-list-wrap {
    position: relative;
    flex: 1;
    overflow: hidden;
  }
  .van-tabs {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    // .van-tabs--line .van-tabs__wrap {
    //   height: 50px;
    // }
  }
  .van-tabs__content {
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
  }
  .van-tabs__nav {
    margin-right: 46px;
  }
  .user-todo-list-container {
    /*overflow-y: auto;*/
    /*height: 100%;*/
  }
  .filter-btn {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1;
    color: $--color-primary;
    font-size: 16px;
  }
}
</style>
